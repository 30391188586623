module.exports = [
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "streams",
        "type": "address[]"
      }
    ],
    "name": "readStreams",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];