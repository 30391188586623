module.exports = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address[]",
        "name": "streams",
        "type": "address[]"
      },
      {
        "indexed": false,
        "internalType": "string[]",
        "name": "reasons",
        "type": "string[]"
      }
    ],
    "name": "FundStreams",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "austinCanCleanUpDust",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "austinGriffith",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "buidlGuidl",
    "outputs": [
      {
        "internalType": "address payable",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "streams",
        "type": "address[]"
      },
      {
        "internalType": "string[]",
        "name": "reasons",
        "type": "string[]"
      }
    ],
    "name": "fundStreams",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
];